@import '../../node_modules/common-web-ui/styles/variables.less';
@import './utilities/utilities.less';

//============================================
// Shadow
//============================================
@box-shadow-lighter: 0 0.3rem 0.5rem 0 rgba(0, 0, 0, 0.3);
@box-shadow-default: 0 0.3rem 0.7rem 0 rgba(0, 0, 0, 0.3);

//============================================
// Font
//============================================
// Font size
@font-size-xxl: 3.2rem;
@font-size-xxxxl: 6.4rem;

@line-height-large: 2.7rem;
@line-height-xxl: 4.2rem;

//============================================
// Border Radius
//============================================
@border-radius-default: 0.3rem;

//============================================
// Palette
//============================================
@palette-primary-color: #005eb8;
@palette-light-blue: #48a5ff;
@palette-white: #ffffff;
@palette-purple: #ab77b3;
@palette-green: #5faead;
@palette-yellow: #c4b44f;
@palette-blue-dark: #19345e;
@palette-blue-light: #0d498b;
@palette-gray-light: #f5f9fc;
@palette-gray-medium: #4a4a4a;
@palette-gray-dark: #353b3e;
@palette-green: #85c85a;
@palette-success-green: #2cbe7c;

@palette-gray-light2: #acb4b9;
@palette-red: #e52e39;
@palette-red-light: #f5b6ba;
@palette-orange: #f0b322;

@palette-black: #000000;

//============================================
// Button
//============================================
@btn-primary-color: @palette-primary-color;
@btn-primary-bg: @palette-white;

@btn-lg-padding-vertical: 0.5rem;
@btn-lg-padding-horizontal: 1rem;
@btn-lg-font-size: 1.25rem;
@btn-lg-line-height: 1.5;
@btn-lg-border-radius: 0.3rem;

@btn-sm-padding-vertical: 0.25rem;
@btn-sm-padding-horizontal: 0.5rem;
@btn-sm-font-size: 0.875rem;
@btn-sm-line-height: 1.5;
@btn-sm-border-radius: 0.2rem;

//============================================
// Text
//============================================
@text-white: @palette-white;
@text-primary: @palette-primary-color;

//============================================
// Spacing
//============================================

//============================================
// Override for waiting-indicator
//============================================
:root {
  --color-default-icon: @text-white;
  --color-static-text: @palette-black;
}
