.placeholder(@color) {
  &::-webkit-input-placeholder {
    color: @color;
  }
  &::-moz-placeholder {
    color: @color;
  }
  &:-ms-input-placeholder {
    color: @color;
  }
}

.tab-focus(@color) {
  // Default
  outline: thin dotted #333;
  // Webkit
  outline: 0.8rem auto @color;
  outline-offset: -0.2rem;
}

.box-shadow(@shadow) {
  -webkit-box-shadow: @shadow;
  -moz-box-shadow: @shadow;
  box-shadow: @shadow;
}

.transition(@transition) {
  -webkit-transition: @transition;
  -moz-transition: @transition;
  -ms-transition: @transition;
  -o-transition: @transition;
  transition: @transition;
}

.button-variant(@color; @background; @border) {
  color: @color;
  background-color: @background;
  border: solid 0.1rem;
  border-color: @border;
  box-sizing: border-box;

  &:focus,
  &.focus {
    color: @color;
    background-color: darken(@background, 10%);
    border-color: darken(@border, 25%);
  }
  &:hover {
    color: @color;
    background-color: darken(@background, 10%);
    border-color: darken(@border, 12%);
  }
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    color: @color;
    background-color: darken(@background, 10%);
    border-color: darken(@border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: @color;
      background-color: darken(@background, 17%);
      border-color: darken(@border, 25%);
    }
  }
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      background-color: @background;
      border-color: @border;
    }
  }

  .badge {
    color: @background;
    background-color: @color;
  }
}

// Button sizes
.button-size(@padding-vertical; @padding-horizontal; @font-size; @line-height; @border-radius) {
  padding: @padding-vertical @padding-horizontal;
  font-size: @font-size;
  line-height: @line-height;
  border-radius: @border-radius;
}

.svg-replace(@src, @default-value, @new-value) {
  @escape-default-value: escape(@default-value);
  @escape-new-value: escape(@new-value);
  @data-uri: data-uri('image/svg+xml;charset=UTF-8', '@{src}');
  @replace-src: replace('@{data-uri}', '@{escape-default-value}', '@{escape-new-value}');
  background-image: e(@replace-src);
}
