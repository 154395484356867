input {
  // FIX for Safari iOS input inner shadow
  //   https://zomigi.com/blog/bug-fixes-for-removing-the-inner-shadow-on-ios-inputs/
  background-image: -webkit-gradient(
    linear,
    0% 0%,
    0% 100%,
    from(hsla(0, 0%, 100%, 0)),
    to(hsla(0, 0%, 100%, 0))
  );
  background-image: -webkit-linear-gradient(hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0));
}

input[type='email']::placeholder,
input[type='search']::placeholder,
input[type='text']::placeholder,
input[type='password']::placeholder,
input[type='number']::placeholder,
input[type='tel']::placeholder,
input[type='time']::placeholder,
input[type='week']::placeholder,
textarea::placeholder {
  color: @palette-gray-light2;
}

daui-dialog {
  .wrapper {
    margin: 0;
    background-color: @palette-color-hydrogen !important;
  }
  // OVERIDE NG AUTOCOMPLETE
  > .overlay {
    z-index: 1;
  }
}

h2.title,
.action,
.form-row label {
  // OVERIDE DAUI SIGNPOSTS
  daui-signposts.tooltip {
    display: inline-block;
    margin-left: 0.6rem;

    .wrapper .signpost[clrdropdowntrigger].dropdown.dropdown-toggle .action .icon {
      background: @palette-color-hydrogen;
      -webkit-mask: url('../../node_modules/common-web-ui/assets/images/icon-navigation-help.svg')
        no-repeat center;
      mask: url('../../node_modules/common-web-ui/assets/images/icon-navigation-help.svg') no-repeat
        center;
      -webkit-mask-size: 1.8rem 1.8rem;
      mask-size: 1.8rem 1.8rem;
    }
  }
}

daui-waiting-indicator {
  > .wrapper {
    > .item {
      background: @palette-color-hydrogen !important;
    }
  }
}
