// --------------------------------------------------//
// Utilities
// --------------------------------------------------//

@import 'mixins.less';

// variables
@import 'utilities/variables.less';

// float
@import 'utilities/float.less';

// text
@import 'utilities/text.less';

// spacing
@import 'utilities/spacing.less';

/*-- float utilities --*/
.float-common-utilities;
.float-responsive-utilities;

/*-- text utilities --*/
.text-common-utilities;
.text-responsive-utilities;

/*-- spacing utilities --*/
.spacing-common-utilities;
.spacing-responsive-utilities;

//============================================
// Utilities
//============================================

.align-baseline {
  vertical-align: baseline;
}
.align-top {
  vertical-align: top;
}
.align-middle {
  vertical-align: middle;
}
.align-bottom {
  vertical-align: bottom;
}
.align-text-bottom {
  vertical-align: text-bottom;
}
.align-text-top {
  vertical-align: text-top;
}
