@control-placeholder: #acb4b9;
ng-autocomplete {
  width: 100%;
  .autocomplete-container {
    height: 5rem;
    .input-container {
      position: relative;
      height: 100%;
      input {
        height: 100%;
        padding: 1.2rem 1.2rem;
        font-size: 1.8rem;
        font-weight: 400;
        line-height: 2.5rem;
      }
      input[type='text']::placeholder {
        color: @control-placeholder;
      }

      > .x {
        right: 2.8rem;
      }

      //OVERRIDE COMMON UI
      @media only screen and (min-width: 768px) {
        input[type='text'] {
          max-width: 54.5rem;
        }
      }
    }

    .suggestions-container ul li a {
      font-size: 1.8rem;
    }
    .suggestions-container {
      max-height: 16rem;
    }
    .suggestions-container ul {
      max-height: 16rem;
    }
  }
  &.ng-invalid {
    input {
      &.ng-touched {
        border: 0.2rem solid @palette-red;
        color: @palette-red-light;
        .svg-replace('../../assets/images/warning.svg', @palette-black, @palette-red);
        .placeholder(@palette-red-light);
        background-position: right calc(0.375em + 0.1875rem) center;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
        padding-right: calc(2.5em + 0.75rem);
        background-repeat: no-repeat;
      }
    }
  }

  .notFound {
    color: @palette-black;
  }
}

ng-autocomplete.above {
  width: 10px;

  .autocomplete-container {
    .suggestions-container {
      position: absolute;
      bottom: 100%;
    }
  }
}
